<template>
  <div class="mx-auto" style="max-width: 800px">
    <h1 class="mb-4">Select an Organization</h1>

    <c-card class="px-4">
      <c-data-table
        :items="$store.state.data.companies"
        :fields="['name', 'licenses_purchased', 'tier', 'last_login']"
        :loading="!!$store.state.loading"
        clickable-rows
        sorter
        table-filter
        @row-clicked="$router.push(`/orgs/${$event.name}`)"
      >
        <template #licenses_purchased="{ item }">
          <td>{{ item.licenses_purchased || '-' }}</td>
        </template>

        <template #tier="{ item }">
          <td>{{ item.tier || '-' }}</td>
        </template>

        <template #last_login="{ item }">
          <td>{{ timeAgo(item.last_login) }}</td>
        </template>
      </c-data-table>
    </c-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  created() {
    this.$store.dispatch('data/getCompanies')
  },

  methods: {
    timeAgo(date) {
      return moment(date).fromNow()
    }
  }
}
</script>
